'use strict';

angular.module('metabuyer')
  .controller('supplierManageCtrl', supplierManageCtrl);

supplierManageCtrl.$inject = [
  '$scope', '$rootScope', 'globalFunc', 'HighlightId', '$state',
  '$stateParams', '$window', '$filter', 'action', 'suppliersDataList', 'approvalSupplier','supplierFunctions', '$translate', '$http',
  '$interval', 'checkExportStatus', 'toastr', 'pathConstants', 'exportSearch'
];

function supplierManageCtrl (
  $scope, $rootScope, globalFunc, HighlightId, $state,
  $stateParams, $window, $filter, action, suppliersDataList, approvalSupplier, supplierFunctions, $translate, $http,
  $interval, checkExportStatus, toastr, pathConstants, exportSearch
) {

  $scope.isTenantAdmin = !!globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'TENANT_SUPER_ADMIN');
  $scope.isAdmin = !!globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'Admin');
  $scope.supplierCompanyContractParams = {
    'class': 'App\\Metabuyer\\Supplier\\Models\\SupplierCompanyContract'
  };

  $scope.tabSelection = tabSelection;
  $scope.getStatusDisplayName = getStatusDisplayName;
  $scope.isCurrentUserSupplierManager = isCurrentUserSupplierManager;
  $scope.approvalSupplier = approvalSupplier;
  $scope.getSelectedSuppliers = getSelectedSuppliers;
  $scope.checkWaitingOnUser = checkWaitingOnUser;
  $scope.multiSelectPendingItems = multiSelectPendingItems;
  $scope.canImportOrExportCompanySupplier = canImportOrExportCompanySupplier;
  $scope.exportCompanySuppliers = exportCompanySuppliers;
  $scope.isSystemSupportRole = globalFunc.isSystemSupportRole();


  function tabSelection(tab) {
    switch (tab) {
      case 'all': {
        $scope.selectedListTab = tab;
        $state.go('main.user.supplier.manage', {
          status: $scope.status,
          tab: tab,
          extraFilterKey: null,
          extraFilterValue: null,
          cursor: null,
          page: null
        });
        break;
      }
      case 'yours': {
        $scope.selectedListTab = tab;
        $state.go('main.user.supplier.manage', {
          status: $scope.status,
          tab: tab,
          extraFilterKey: $scope.status === 'pending' ? 'edit_merged|submission_info|submitter|_id' : 'created_by',
          extraFilterValue: $rootScope.currentUser._id,
          cursor: null,
          page: null
        });
        break;
      }
      case 'waiting': {
        $scope.selectedListTab = tab;
        $state.go('main.user.supplier.manage', {
          status: $scope.status,
          tab: tab,
          extraFilterKey: 'edit_merged|waiting_on|_id',
          extraFilterValue: $rootScope.currentUser._id,
          cursor: null,
          page: null
        });
        break
      }
    }
  }

  function getStatusDisplayName(status) {
    var translateArr = [
      'statuses.all',
      'statuses.active',
      'statuses.draft',
      'statuses.pending',
      'statuses.pendingApproval',
      'statuses.blacklisted',
      'statuses.rejected',
      'statuses.withdrawn',
      'statuses.deactivated',
      'statuses.suspended',
    ];
    status = status === 'inactive' ? 'deactivated' : status;
    $scope.statusDesc = status;
    $translate(translateArr).then(function (trans) {
      $scope.statusDesc = trans["statuses." + status];
    });
  }

  function getSelectedSuppliers() {
    var list = [];
    _.forEach($scope.suppliers, function (supplier) {
      if(supplier.selected){
          supplier.approval_id = supplierFunctions.getApprovalId(supplier);
          list.push(supplier);
      }
    });
    return list;
  }

  /**
   * Check if current user has system role: Supplier Manager
   *
   * @returns {boolean}
   */
  function isCurrentUserSupplierManager() {
    return (supplierFunctions.checkIfUserHasSupplierManagerRole($rootScope.currentUser));
  }

  function canImportOrExportCompanySupplier() {
    return ($scope.isTenantAdmin || $scope.isAdmin || isCurrentUserSupplierManager() || $scope.isSystemSupportRole )
      && ($scope.status !== 'draft' && $scope.status !== 'pending');
  }

  function exportCompanySuppliers() {
    $scope.checkingExportStatus = true;
    toastr.info('Data is being exported. The download will start automatically', {timeOut: 0, extendedTimeOut: 0});

    $http.get($filter('format')(pathConstants.apiUrls.supplier.exportCompanySupplier),
      { params: exportSearch.getParams()}
    ).then(function (resource) {
      if (!!resource && !!resource.data) {
        $scope.startChecking = $interval(function () {
          checkExport(resource.data.content.data.export_log_id)
        }, 5000);

      }
    }, function () {
      $scope.checkingExportStatus = true;
      toastr.error('Failed to download file');
    });
  }

  function checkExport(id){
    checkExportStatus.get(
      {
        id: id
      },
      function(resource){
        if(!!resource.content && !!resource.content.data &&
          !!resource.content.message && !!resource.content.data.status){

          /**
           * Clearing the toastr before every call for status checking to simulate 'refreshing' the toastr
           */
          toastr.clear();

          var checkingStatus = true;

          /**
           * Status for export CSV
           * 0 = PENDING
           * 1 = IN_PROGRESS
           * 2 = DONE
           * 3 = FAILED
           * 4 = CANCELLED
           */
          switch(resource.content.data.status){
            case 0:
              toastr.info(resource.content.message, {timeOut: 0, extendedTimeOut: 0});
              break;
            case 1:
              toastr.info(resource.content.message, {timeOut: 0, extendedTimeOut: 0});
              break;
            case 2:
              /**
               * If has is returned
               */
              if(!!resource.content.data){
                toastr.success(resource.content.message,
                  {timeOut: 10000, extendedTimeOut: 0, onClose: getCompanySupplierExport(id)});
              }
              /**
               * Else condition to handle case if hash not returned by backend
               */
              else{
                toastr.error('There was an error with the export. Please try again');
              }
              checkingStatus = false;
              $interval.cancel($scope.startChecking);
              break;
            case 3:
              checkingStatus = false;
              toastr.error(resource.content.message, {timeOut: 0, extendedTimeOut: 0});
              $interval.cancel($scope.startChecking);
              break;
            case 4:
              checkingStatus = false;
              toastr.info(resource.content.message, {timeOut: 0, extendedTimeOut: 0});
              $interval.cancel($scope.startChecking);
              break;
          }

          $scope.checkingExportStatus = checkingStatus;
        }
      },
      function(error){
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function getCompanySupplierExport(exportLogId) {
    var param = {
      export_log_id: exportLogId
    };

    $http.get($filter('format')(pathConstants.apiUrls.minion.getExportFile, param),
      {}
    ).then(function (response) {
      var data = typeof response.data === 'object' ? JSON.stringify(response.data) : response.data;
      var blob = new Blob([data]);
      var link = document.createElement('a');
      document.body.appendChild(link);
      link.href = window.URL.createObjectURL(blob);
      var filename = 'SupplierCompanyContract-' + $filter('date')(new Date(), 'dd-MM-yyyy-hh-mm-ss') +'.csv';
      link.download = filename;
      link.click();
      toastr.success('File ' + filename + ' is downloaded.');
    });
  }

  function initialize() {

    $scope.suppliers = suppliersDataList.data;
    $scope.actionType = action;
    $scope.title = $stateParams.query;

    if ($stateParams.type === 'date') {
      var ms = Number($stateParams.query[1] * 1000);
      $scope.title = $filter('date')(new Date(ms), 'dd MMM yyyy');
    }
    else {
      $scope.title = $stateParams.query;
    }

    $scope.meta = suppliersDataList.meta;
    $scope.status = $stateParams.status;
    $scope.$parent.status = $stateParams.status;

    if ($scope.status === 'pending') {
      $scope.storageKey = 'suppliers-management-pending-selected-columns';
      $scope.template = 'app/suppliersManagement/manage/pendingTemplate.html';
    }
    else if ($scope.status === 'draft') {
      $scope.storageKey = 'suppliers-management-draft-selected-columns';
      $scope.template = 'app/suppliersManagement/manage/draftTemplate.html';
    }
    else {
      $scope.storageKey = 'suppliers-management-selected-columns';
      $scope.template = 'app/suppliersManagement/manage/generalTemplate.html';
    }
    $scope.selectedListTab = $stateParams.tab;
    if (!$scope.selectedListTab) {
      $scope.selectedListTab = 'all';
    }
    //set status for parent
    if (!!HighlightId.getId()) {

      $scope.highlightId = HighlightId.getId();

      if ($scope.highlightId != null && $scope.highlightId != undefined) {

        //scroll the window to top
        $window.scroll(0, 0);
      }

      //clear current id
      HighlightId.setId(null);
    }
    getStatusDisplayName($scope.status);

    // decrypt reg_no
    if (!$rootScope.isV2Enabled) {
      $scope.suppliers.forEach(function (item, index) {
        if (!!item.basic_info.reg_no) {
          $scope.suppliers[index].basic_info.reg_no = atob(item.basic_info.reg_no);
        }
      });
    }
  }

  function checkWaitingOnUser(supplier) {
    if(!!supplier.edit_merged.waiting_on && !_.isEmpty(supplier.edit_merged.waiting_on)) {
      return _.findIndex(supplier.edit_merged.waiting_on, {'_id': $rootScope.currentUser._id}) > -1;
    } else {
      return false;
    }
  }


  /**
   *  auto select/deselect the items for approval action
   * @param status
   */
  function multiSelectPendingItems (status) {
    _.forEach($scope.suppliers, function (supplier) {
      if (checkWaitingOnUser(supplier)) {
        supplier.selected = status;
      }
    });
  }


  initialize();
}
