'use strict';
/**
 * @name approvalFlowDetailController
 * @desc Controller for Approval Flow Detail
 */

function approvalFlowPreviewController($scope, globalFunc, companies, searchModule, companyExpenseTypes,
                                       companyCurrencies, toastr, approvalPreviewByData, profile, $rootScope,
                                       postApprovalPreviewByData, $http, $filter, pathConstants) {

  $scope.uiCompany = {};
  $scope.uiCompany.list = {};
  $scope.companyListing = [];
  $scope.uiCostCenter = {};
  $scope.uiCostCenter.list = {};
  $scope.expenseTypesListing = null;
  $scope.uiExpense = {};
  $scope.uiExpense.list = {};
  $scope.uiAmount = 1;
  $scope.uiOverbudgetPercentage = 0;
  $scope.uiOverbudgetAmount = 0;
  $scope.uiCumulativeApprovedAmount = 0;
  $scope.uiItemType = {};
  $scope.uiItemType.list = [];
  $scope.uiActivityCode = {};
  $scope.uiActivityCode.list = {};
  $scope.modesOfPurchase = [];
  $scope.activities = [];
  $scope.searchItemGroup = searchItemGroup;
  $scope.selectItemGroup = selectItemGroup;
  $scope.selectedModeOfPurchase = {
    selected: undefined
  };
  var defaultModeOfPurchase = {
    '_id': null,
    'code': 'COMPANY',
    'name': 'Company',
    'uuid': 'COMPANY'
  };
  $scope.itemTypeListing = [
    {
      code: 'IT',
      descr: 'Information Technology'
    },
    {
      code: 'CP',
      descr: 'Car Purchase'
    },
    {
      code: 'ME',
      descr: 'M&E'
    },
    {
      code: 'FB',
      descr: 'Food, Beverages and Tobacco'
    }
  ];
  $scope.currencyListing = [];
  $scope.uiCurrency = {};
  $scope.uiCurrency.list = {};
  $scope.uiCatalog = {};
  $scope.uiCatalog.list = {code: 'noncatalog', descr: 'Catalog & Non Catalog'};
  $scope.catalogListing = [
    {
      code: 'catalog',
      descr: 'Catalog Only'
    },
    {
      code: 'noncatalog',
      descr: 'Catalog & Non Catalog'
    }
  ];
  $scope.uiType = {};
  $scope.uiType.list = {code: 'PR', descr: 'PR'};
  $scope.typeListing = [];
  $scope.templateTypes = [];
  $scope.approvals = null;

  $scope.processCostCenterList = processCostCenterList;
  $scope.preview = preview;
  $scope.resetSelection = resetSelection;
  $scope.isPreviewType = isPreviewType;
  $scope.loadActivities = loadActivities;
  $scope.disableActivityInput = disableActivityInput;

  function filterCompanyListing() {
    $scope.availableCompanies = profile.companies;
    if (globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Approval Flow Viewer')){
      companies.data.forEach(function(company){
        $scope.availableCompanies.forEach(function(availableCompany){
          if (company.descr === availableCompany.descr) {
            $scope.companyListing.push(company);
          }
        })
      })
    } else {
      $scope.companyListing = companies.data;
    }
  }

  function initializeTemplateTypes() {
    $scope.templateTypes = [
      {
        code: 'PR',
        descr: 'PR'
      },
      // {
      //   code: 'BUDGETCREATE',
      //   descr: 'Budget Creation'
      // },
      // {
      //   code: 'BUDGETREVISION',
      //   descr: 'Budget Revision'
      // },
      {
        code: 'Delegation',
        descr: 'Delegation'
      },
      {
        code: 'PURCHASEORDER',
        descr: 'Purchase Order'
      }
      // Commented out for now, this listing entry is being shown in production
      // It also doesn't work
      // {
      //   code: 'APPROVALLIMITCONTROL',
      //   descr: 'Approval Limit Control'
      // }
    ];

    if ($rootScope.isV2Enabled) {
      if (!!globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'System Support') ||
      !!globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'TENANT_SUPER_ADMIN') ||
      !!globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'ADMIN')) {
        $scope.templateTypes.push({
          code: 'APPROVALLIMITCONTROL',
          descr: 'Approval Limit Control'
        },
        {
          code: 'SUPPLIERCOMPANYCONTRACT',
          descr: 'Company Supplier'
        });
      }
    }

    if (globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Approval Flow Viewer') === false){
      $scope.templateTypes.push({
        code: 'Supplier',
        descr: 'Supplier'
      },
      {
        code: 'Catalog',
        descr: 'Catalog'
      });
    }

    if (!!globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'DEVELOPER') ||
    !!globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'System Support') ||
    !!globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'TENANT_SUPER_ADMIN') ||
    !!globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'ADMIN')) {
      $scope.templateTypes.push({
        code: 'BUDGETCREATE',
        descr: 'Budget Creation'
      },
      {
        code: 'BUDGETREVISION',
        descr: 'Budget Revision'
      });
    }

    if ($rootScope.isV2Enabled) {
      $scope.templateTypes.push({
        code: 'TENDER',
        descr: 'Tender'
      });
    }

    if (!$rootScope.isV2Enabled) {
      $scope.templateTypes.push({
        code: 'Budget',
        descr: 'Budget'
      });
    }
  }

  function loadCurrencies() {
    companyCurrencies.get({
        id: $scope.uiCompany.list._id
      },
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.currencyListing = resource.content.data;
        }
      }, function (error) {
        globalFunc.objectErrorMessage(error);
      });
  }

  function loadExpenseTypes() {
    companyExpenseTypes.get(
      {
        id: $scope.uiCompany.list._id
      },
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.expenseTypesListing = resource.content.data;
          if (!!$scope.uiType.list && $scope.uiType.list.code === 'Budget') {
            $scope.expenseTypesListing.unshift({'code': 'all', 'descr': 'All'});
          }
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function searchItemGroup(val) {
      var moduleParam = {
          module: 'item-group'
      };

      var additionalParams = {
          'criteria[0][is_active]': true,
          'criteria[1][name]': val,
          'criteria[1][code]': val,
          criteria_operator: 'or',
          offset: 10
      };

      return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
          params: additionalParams,
          ignoreLoadingBar: true
      }).then(function (response) {
          if (!!response.data && !!response.data.content && !!response.data.content.data) {
              return response.data.content.data.map(function (item) {
                  return item
              });
          }
      });
  }

  function selectItemGroup(item) {
      $scope.selectedItemGroup = item;
  }

  function processCostCenterList() {
    $scope.uiCostCenter.list = {};
    $scope.uiExpense.list = [];
    $scope.modesOfPurchase = [];
    $scope.selectedModeOfPurchase = {
      selected: undefined
    };
    //stop when no company is selected
    if (!$scope.uiCompany.list.code) {
      $scope.costCenterListing = [];
      return;
    }

    loadExpenseTypes();
    loadCurrencies();


    //set the mode of purchase for the select company
    $scope.modesOfPurchase = !!$scope.uiCompany.list.modes_of_purchase ?
      _.cloneDeep($scope.uiCompany.list.modes_of_purchase) : [];
    $scope.modesOfPurchase.unshift(defaultModeOfPurchase);

    var params = {
      module: 'cost-center',
      offset: 0,
      criteria_operator: 'and',
      order_by: 'descr',
      order: 1
    };

    if ($scope.uiCompany.list.code !== 'SYSTEM')
      params['criteria[company_code]'] = $scope.uiCompany.list.code;

    searchModule.get(
      params,
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.costCenterListing = resource.content.data;
          $scope.costCenterListing.unshift({'code':'all', 'descr':'All'});
        }
      }
    )
  }

  function validationBeforePreview() {
    //non-PR types are not required to fill in the rest of the fields
    if (!!$scope.uiType.list.code && ['PR', 'Budget', 'PURCHASEORDER'].indexOf($scope.uiType.list.code) < 0) {
      return true;
    }

    switch ($scope.uiType.list.code) {
      case 'PR':
        if (!$scope.uiCompany.list.code || !$scope.uiCostCenter.list.code || !$scope.uiExpense.list.code ||
          $scope.uiAmount <= 0 || !$scope.uiCurrency.list.code || !$scope.uiCatalog.list.code ||
          !$scope.uiType.list.code
        ) {
          toastr.error('Please fill in all the required fields and total amount must be greater than 0.');
          return false;
        } else if ($scope.uiOverbudgetPercentage < 0) {
          toastr.error('Exceed Budget Percentage cannot be a negative value.');
          return false;
        } else if ($scope.uiOverbudgetAmount < 0) {
          toastr.error('Exceed Budget Amount cannot be a negative value.');
          return false;
        } else if ($scope.uiCumulativeApprovedAmount < 0) {
          toastr.error('Cumulative Approved Amount cannot be a negative value.');
          return false;
        } else if ($scope.uiExpense.list.code === 'SPKOPEX' && ($scope.uiCostCenter.list.code !== 'all') && !$scope.uiActivityCode.list) {
          toastr.error('Activity Code cannot be empty')
          return false;
        }
        break;

      case 'Budget':
        if (!$scope.uiCompany.list.code || !$scope.uiCostCenter.list.code || !$scope.uiExpense.list.code ||
          $scope.uiAmount <= 0 || !$scope.uiCurrency.list.code
        ) {
          toastr.error('Please fill in all the required fields and total amount must be greater than 0.');
          return false;
        }
        break;

      case 'PURCHASEORDER':
        if (!$scope.uiCompany.list.code) {
          toastr.error('Please select a billing company ');
          return false;
        }
        if (!$scope.selectedModeOfPurchase.selected || !$scope.selectedModeOfPurchase.selected.code) {
          toastr.error('Please select a mode of purchase');
          return false;
        }
        break;
    }
    return true;
  }

  function preview() {
    $scope.approvals = null;

    if (!validationBeforePreview())
      return;

    var param = {
      type: $scope.uiType.list.code,
      company: $scope.uiCompany.list.code,
      cost_center: !!$scope.uiCostCenter.list.code ? $scope.uiCostCenter.list.code : 'all',
      expense: $scope.uiExpense.list.code,
      total_amount: $scope.uiAmount,
      item_type: !!$scope.uiItemType.list.length ? _.pluck($scope.uiItemType.list, 'code').join(",") : '',
      currency: $scope.uiCurrency.list.code,
      catalog: $scope.uiCatalog.list.code,
      overbudget_percentage: $scope.uiOverbudgetPercentage,
      overbudget_amount: $scope.uiOverbudgetAmount,
      cumulative_approved_amount: $scope.uiCumulativeApprovedAmount,
      mode_of_purchase: !!$scope.selectedModeOfPurchase.selected ? $scope.selectedModeOfPurchase.selected : undefined,
      item_group: (!!$scope.selectedItemGroup && !!$scope.searchParams.itemGroup) ? $scope.selectedItemGroup.code : undefined
    };

    if ($scope.uiType.list.code === 'PR' && $scope.uiExpense.list.code === 'SPKOPEX' && $scope.uiCostCenter.list.code !== 'all')
      param['cumulative_per_month'] = $scope.uiActivityCode.list.current_month_amount;

    postApprovalPreviewByData.post(
      {},{
        data : param
      },
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.approvals = resource.content.data;
          generatePrint();
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  /**
   * Checks for the current preview type
   *
   * @param {array} type Preview Type
   * @returns {boolean}
   */
  function isPreviewType(type) {
    return !!$scope.uiType.list.code && type.indexOf($scope.uiType.list.code) > -1;
  }

  /**
   * Function that reset searching fields
   * @param {boolean} isResetButton  Calling from either 'reset button' or on type selecting
   * @param {boolean} resetTypeField Whether to reset Type searching field
   */
  function resetSelection(isResetButton, resetTypeField) {
    if ((!!isResetButton) ||
      (!isResetButton && !!$scope.uiType && !!$scope.uiType.list && !!$scope.uiType.list.code &&
      $scope.uiType.list.code !== 'PR')) {
      $scope.uiCompany.list = [];
      $scope.uiCostCenter.list = {};
      $scope.uiExpense.list = [];
      $scope.uiAmount = 0;
      $scope.uiItemType.list = [];
      $scope.uiCurrency.list = [];
      $scope.uiCatalog.list = [];
      if (!!resetTypeField) {
        $scope.uiType.list = [];
      }
      $scope.uiActivityCode.list = {};
    }
  }

  function loadActivities() {
    if ($scope.uiType.list.code !== 'PR' ||
        (!!$scope.uiExpense.list.code && $scope.uiExpense.list.code !== 'SPKOPEX') ||
        (!!$scope.uiCostCenter.list.code && $scope.uiCostCenter.list.code === 'all'))
     return;

    $scope.uiActivityCode.list = {};
    var moduleParam = {
      module: 'activity'
    };

    var additionalParams = {
      'criteria[0][company|code]': $scope.uiCompany.list.code,
    };

    if ($scope.uiCostCenter.list.code !== 'all')
      additionalParams['criteria[1][cost_center|code]'] = $scope.uiCostCenter.list.code

    return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
        params: additionalParams,
        ignoreLoadingBar: true
    }).then(function (response) {
        if (!!response.data && !!response.data.content && !!response.data.content.data) {
            $scope.activities = response.data.content.data;
        }
    });
  }

  function disableActivityInput() {
    if ($scope.uiCostCenter.list.code === 'all') return true;
    return (
      (!$scope.uiCompany.list.code || !$scope.uiCostCenter.list.code) || $scope.uiExpense.list.code !== 'SPKOPEX'
    );
  }

  function initialize() {
    filterCompanyListing();
    initializeTemplateTypes();
  }

  initialize();
}

approvalFlowPreviewController.$inject = ['$scope', 'globalFunc', 'companies', 'searchModule', 'companyExpenseTypes',
  'companyCurrencies', 'toastr', 'approvalPreviewByData', 'profile', '$rootScope', 'postApprovalPreviewByData',
  '$http', '$filter', 'pathConstants'];

angular
  .module('metabuyer')
  .controller('approvalFlowPreviewController', approvalFlowPreviewController);
